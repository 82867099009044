var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('h2', [_vm._v("Legacy Touchpoints Reporting")]), _c('b-row', [_c('b-col', [_c('br'), _vm._v(" Replaced by: "), _c('br'), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/index.php"
    }
  }, [_vm._v("Full Report")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/top10contacts.php"
    }
  }, [_vm._v("Top 10 Connections")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/top10tastings.php"
    }
  }, [_vm._v("Top 10 Tastings")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/top10meetings.php"
    }
  }, [_vm._v("Top 10 Meetings")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/top10calls.php"
    }
  }, [_vm._v("Top 10 Calls")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/top10spent.php"
    }
  }, [_vm._v("Top 10 Money Spent "), _c('br')]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/top10placements.php"
    }
  }, [_vm._v("Top 10 Placements")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/connectionsbytype.php"
    }
  }, [_vm._v("Connections by Type")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/newaccounts.php"
    }
  }, [_vm._v("New Accounts")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/over2months.php"
    }
  }, [_vm._v("Not contacted over 2 months")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/tastingreport.php"
    }
  }, [_vm._v("Tasting Report")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/nevercontacted.php"
    }
  }, [_vm._v("Never contacted")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/distributor_list.php"
    }
  }, [_vm._v("Distributor List")]), _c('br'), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.koval-distillery.com/contactreport/allcrmentries.php"
    }
  }, [_vm._v("All CRM entries")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }